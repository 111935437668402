import { API_URL } from '../../../app-config/constants';
import { remove as deleteRequest, get as getRequest, post as postRequest } from '../request';
const BASE_URL = `${API_URL}/reaction`;
getAll.operation = 'READ';
export function getAll(containerId) {
    return getRequest(`${BASE_URL}/${containerId}`);
}
create.operation = 'CREATE';
create.invalidates = ['getAll'];
export function create(request) {
    return postRequest(`${BASE_URL}`, { postData: request });
}
update.operation = 'UPDATE';
export function update(feedback) {
    postRequest(`${BASE_URL}/${feedback.id}`, { postData: feedback });
    return Promise.resolve(feedback); // We resolve immediately since there's no need to wait for the request to complete.
}
remove.operation = 'DELETE';
remove.byId = true;
export function remove(id) {
    return deleteRequest(`${BASE_URL}/${id}`);
}
